import { Dispatch } from "react";
import { Button, Text } from "../../../components";

type Props = {
  value: boolean | null;
  setValue: Dispatch<boolean | null>;
  disabled?: boolean;
};

export default function PimProductAuctionFilter({
  value,
  setValue,
  disabled,
}: Props) {
  const items = [
    { name: "products.list.allButton", id: null },
    { name: "types.auctions.hasAuction", id: true },
    { name: "types.auctions.noAuctions", id: false },
  ];
  const handleSetValue = (id: boolean | null) => {
    return () => {
      const isEqual = id === value;
      !isEqual && setValue(id);
    };
  };
  return (
    <section className="flex flex-wrap items-center gap-2">
      {items.map((e) => {
        const active = e.id === value;
        return (
          <Button
            key={e.name}
            type="button"
            variant={active ? "primary" : "white"}
            onClick={handleSetValue(e.id)}
            disabled={disabled}
            className="py-1 px-2 text-sm"
          >
            <Text>{e.name}</Text>
          </Button>
        );
      })}
      <span className="flex-1" />
      {/* {productTypes.map((e) => {
        const active = e.id === location.pathname;
        return (
          <Button
            key={e.name}
            as={Link}
            to={e.id}
            type="button"
            variant="primary"
            outline
            replace
            className={cn(
              "bg-white rounded-full px-5",
              active
                ? "text-primary border-primary"
                : "text-placeholder border-placeholder"
            )}
          >
            <Text>{e.name}</Text>
          </Button>
        );
      })} */}
    </section>
  );
}
